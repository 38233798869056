import React, { PropsWithChildren, forwardRef, memo, useRef } from 'react';

import { ContentWrapper } from '../components/ContentWrapper';
import { ErrorBoundary } from '../components/ErrorBoundary';
import * as ROUTES from '../constants/routes.constants';
import { CallsPage } from '../pages/calls/Calls.page';
import { Home } from '../pages/home/Home.page';
import { NumberDetailPage } from '../pages/number-detail/NumberDetail.page';
import { NumbersPage } from '../pages/numbers/Numbers.page';
import { GlobalDataProvider } from '../state/app/global/GlobalProvider';

import { Box, Flex } from '@aircall/tractor-v2';
import { AdminRoutesWrapper } from '@components/AdminRoutesWrapper';
import { AgentHeader } from '@components/AgentHeader/AgentHeader';
import { ConnectedAsBanner } from '@components/ConnectedAsBanner/ConnectedAsBanner';
import { DownloadAppScreen } from '@components/DownloadAppScreen/DownloadAppScreen';
import GenericIntegration from '@components/GenericIntegration/GenericIntegration';
import { GlobalModals } from '@components/GlobalModals';
import { KycBanner } from '@components/KycBanner';
import { KycFloatingLockBanner } from '@components/KycFloatingLockBanner';
import KycVerificationPage from '@components/KycVerificationPage/KycVerificationPage';
import { LegacyDashboard, LegacyDashboardProvider } from '@components/LegacyDashboard';
import { NotFoundErrorScreen } from '@components/NotFoundErrorScreen/NotFoundErrorScreen';
import { OnboardingTrialWrapper } from '@components/OnboardingTrialWrapper';
import { PaymentAlert } from '@components/PaymentAlert';
import {
  HasAccess,
  PermissionsModal,
  PermissionsProvider,
  RouteAccess,
} from '@components/Permissions';
import { Sidenav } from '@components/Sidenav';
import { SIDENAV_ITEMS_CONFIG } from '@components/Sidenav/Sidenav.config';
import { TrialBanner } from '@components/TrialBanner/TrialBanner';
import { APP_CONFIG } from '@constants/environment.constants';
import { PERMISSIONS_ACTIONS, RESOURCE } from '@constants/permissions.constants';
import {
  Route as ModuleFederationRoute,
  Placeholder,
  useDashboardExtensionContext,
} from '@dashboard/extension';
import { Loading, PersistedSearchParams, SaveBarProvider } from '@dashboard/library';
import { NotFoundError } from '@helpers/errors.helpers';
import { useAppLayout } from '@hooks/useAppLayout/useAppLayout';
import { useCurrentUser } from '@hooks/useCurrentUser/useCurrentUser';
import { useDataDogRum } from '@hooks/useDataDogRum';
import { useAdminFeatures } from '@hooks/useFeatures/useAdminFeatures';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useIntercomSetup } from '@hooks/useIntercomSetup';
import { useModuleFederationContext } from '@hooks/useModuleFederationContext/useModuleFederationContext';
import { useRoutePath } from '@hooks/useRoutePath';
import { ApplicationContainerPage } from '@pages/application/ApplicationContainer.page';
import { CallsBlockedNumbersPage } from '@pages/calls-blocked-numbers/CallsBlockedNumbers.page';
import { CallsHistoryPage } from '@pages/calls-history/CallsHistory.page';
import { CallsTagsPage } from '@pages/calls-tags/CallsTags.page';
import ErrorTestPage from '@pages/error-test/ErrorTest.page';
import { IntegrationFlowCompletePage } from '@pages/integration-flow-complete/IntegrationFlowComplete.page';
import { IntegrationFlowCreatePage } from '@pages/integration-flow-create/IntegrationFlowCreate.page';
import { IntegrationPageDataProvider } from '@pages/integration-flow-create/IntegrationPageDataProvider';
import { IntegrationFlowInstallPage } from '@pages/integration-flow-install/IntegrationFlowInstall.page';
import { IntegrationFlowRedirect } from '@pages/integration-flow-install/IntegrationFlowRedirect';
import { ActivityFeed } from '@pages/legacy-analytics/ActivityFeed.page';
import { OauthAuthorizeSuccessPage } from '@pages/oauth-authorize-success/OauthAuthorizeSuccess.page';
import { OauthAuthorizePage } from '@pages/oauth-authorize/OauthAuthorize.page';
import { OauthLoginPage } from '@pages/oauth-login/oauth-login.page';
import { OnboardingPage } from '@pages/onboarding';
import { SandboxSSOPage } from '@pages/sandbox-sso/SandboxSSO.page';
import { StatsAnalyticsPlusPage } from '@pages/stats-analytics-plus/StatsAnalyticsPlus.page';
import { StatsAvailabilitiesPage } from '@pages/stats-availabilities/StatsAvailabilities.page';
import { StatsCallQualityProPage } from '@pages/stats-call-quality-pro/StatsCallQualityPro.page';
import { StatsCallQualityPage } from '@pages/stats-call-quality/StatsCallQuality.page';
import { StatsInboundActivityProPage } from '@pages/stats-inbound-activity-pro/StatsInboundActivityPro.page';
import { StatsInboundActivityPage } from '@pages/stats-inbound-activity/StatsInboundActivity.page';
import { StatsMonitoringPage } from '@pages/stats-monitoring/StatsMonitoring.page';
import { StatsOutboundActivityProPage } from '@pages/stats-outbound-activity-pro/StatsOutboundActivityPro.page';
import { StatsOutboundActivityPage } from '@pages/stats-outbound-activity/StatsOutboundActivity.page';
import { StatsOverviewPage } from '@pages/stats-overview/StatsOverview.page';
import { StatsUnansweredCallsProPage } from '@pages/stats-unanswered-calls-pro/StatsUnansweredCallsPro.page';
import { StatsUnansweredCallsPage } from '@pages/stats-unanswered-calls/StatsUnansweredCalls.page';
import { StatsUsersActivityProPage } from '@pages/stats-users-activity-pro/StatsUsersActivityPro.page';
import { StatsUsersActivityPage } from '@pages/stats-users-activity/StatsUsersActivity.page';
import { UnauthorizedPage } from '@pages/unauthorized/Unauthorized.page';
import { ZendeskSSOPage } from '@pages/zendesksso/zendesksso.page';
import { AgentDataProvider } from '@state/app/agent/AgentDataProvider';
import { DashboardExtensionDataProvider } from '@state/app/module-federation/DashboardExtensionDataProvider';
import { AppLayoutProvider } from '@state/app/ui/AppLayoutProvider';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes, matchPath } from 'react-router-dom';

/**
 * Block accessing the routes disabled on the sidebar.
 */
function ContentWithinNavigation() {
  const featuresFlags = useFeaturesFlags();
  const adminFeatures = useAdminFeatures();
  const features = { ...featuresFlags, ...adminFeatures };
  /**
   * We need to make sure that `areFeaturesLoading` is false
   * before we can check if the route is enabled.
   */
  const areFeaturesLoading = features.loading;
  const { topLevelPath, pathname } = useRoutePath();
  const { currentUser } = useCurrentUser();
  const { currentCompany } = useDashboardExtensionContext();

  // check if the top-level route is enabled
  const topLevelRoute = SIDENAV_ITEMS_CONFIG[topLevelPath];
  // Invisible routs are taken into account for redirection
  if (
    topLevelRoute &&
    !topLevelRoute.enabled(features, currentCompany, currentUser) &&
    !areFeaturesLoading
  ) {
    throw new NotFoundError('Route not found', { topLevelPath, pathname, topLevelRoute });
  }
  const exactMatchTopLevel = matchPath({ path: topLevelPath, end: true }, pathname);
  const topLevelChildren = topLevelRoute?.children;

  // check if it matches any disabled child route
  if (!exactMatchTopLevel && topLevelChildren) {
    const matchedChild = topLevelChildren.find(({ to }) =>
      matchPath({ path: to, end: true }, pathname)
    );

    // if the child route is not defined or disabled except if its invisible
    if (
      !matchedChild ||
      (!matchedChild.enabled(features, currentCompany, currentUser) && !areFeaturesLoading)
    ) {
      throw new NotFoundError('Route not found', { matchedChild, topLevelPath, pathname });
    }
  }

  return <Outlet />;
}

export function renderModuleFederationRoute(
  route: ModuleFederationRoute,
  WrapperComponent: React.ComponentType<Record<string, unknown>>
) {
  const Component = route.component;

  // ignore permissions if it wasnt set by the extension
  if (!route.permissionResource) {
    return (
      <Route
        path={route.path}
        key={route.path}
        element={
          <WrapperComponent>
            <Component />
          </WrapperComponent>
        }
      />
    );
  }
  return (
    <Route
      key={route.path}
      element={
        <RouteAccess
          action={PERMISSIONS_ACTIONS.READ}
          resource={route.permissionResource as RESOURCE[]}
        />
      }
    >
      <Route
        path={route.path}
        element={
          <WrapperComponent>
            <Component />
          </WrapperComponent>
        }
      />
    </Route>
  );
}

export const Layout = forwardRef<HTMLDivElement>((_, ref) => {
  const footerRef = useRef(null);
  const { fullScreen } = useAppLayout();
  const { showTestKycButtonOnDashboard } = useFeaturesFlags();

  useIntercomSetup();

  return (
    <ErrorBoundary>
      <PermissionsModal />
      <Flex flexDirection='column' h='100%'>
        <Placeholder name='integration-updates-banner' parameters={{}} />
        <ConnectedAsBanner />
        <HasAccess oneOf={[RESOURCE.COMPANY_ROLES]}>
          <PaymentAlert />
        </HasAccess>
        <TrialBanner />
        {showTestKycButtonOnDashboard ? <KycBanner /> : null}
        <KycFloatingLockBanner />
        <Flex
          flexDirection='row'
          flexGrow={1}
          overflow='hidden'
          data-test='private-routes-wrapper'
          // Will update when we have the new color token (https://aircall-product.atlassian.net/browse/DAT-19)
          backgroundColor='#E4E6EA'
          py='xs'
          pr='xs'
        >
          {!fullScreen && <Sidenav />}
          <Flex
            flexDirection='column'
            h='100%'
            flexGrow={1}
            overflow='hidden'
            // will update when we have the new token for radii (https://aircall-product.atlassian.net/browse/DAT-19)
            borderRadius='24px'
          >
            <SaveBarProvider portalContainerRef={footerRef}>
              <Box flexGrow={1} overflowY='auto'>
                {/* Page level routes with the navigation menu on the left  */}
                <ErrorBoundary>
                  <ContentWrapper ref={ref} backgroundColor='surface-default'>
                    <PersistedSearchParams>
                      <ContentWithinNavigation />
                    </PersistedSearchParams>
                  </ContentWrapper>
                </ErrorBoundary>
                {/* Footer to put the save bar */}
                <Box ref={footerRef} />
              </Box>
            </SaveBarProvider>
          </Flex>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
});

export function SimpleLayout({ children }: PropsWithChildren<unknown>) {
  return <ErrorBoundary>{children || <Outlet />}</ErrorBoundary>;
}

function AgentDefaultLayout({ children }: PropsWithChildren<unknown>) {
  return (
    <Flex
      h='100%'
      flexDirection='column'
      backgroundColor='surface-background'
      data-test='call-asset-page'
    >
      <Box minHeight={64}>
        <AgentHeader />
      </Box>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Flex>
  );
}

const AdminRoutes = memo(() => {
  const { t } = useTranslation();
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const { loading, adminRoutesDefault, adminRoutesEmpty } = useModuleFederationContext();

  if (loading) {
    return <Loading data-test='admin-routes-loading' />;
  }

  return (
    <AdminRoutesWrapper>
      <OnboardingTrialWrapper>
        <>
          <GlobalModals />
          <Routes>
            <Route
              path={ROUTES.ZENDESK_SSO}
              element={
                <SimpleLayout>
                  <ZendeskSSOPage />
                </SimpleLayout>
              }
            />
            <Route
              path={ROUTES.OAUTH_LOGIN}
              element={
                <SimpleLayout>
                  <OauthLoginPage />
                </SimpleLayout>
              }
            />
            {APP_CONFIG.environment !== 'production' && (
              <Route
                path={ROUTES.SANDBOX_SSO}
                element={
                  <SimpleLayout>
                    <SandboxSSOPage />
                  </SimpleLayout>
                }
              />
            )}

            <Route element={<RouteAccess resource={RESOURCE.INTEGRATIONS} />}>
              {/* Route with simple layout */}
              <Route
                path={ROUTES.INTEGRATION_AUTHORIZE}
                element={
                  <SimpleLayout>
                    <OauthAuthorizePage />
                  </SimpleLayout>
                }
              />
              <Route
                path={ROUTES.INTEGRATION_AUTHORIZE_SUCCESS}
                element={
                  <SimpleLayout>
                    <OauthAuthorizeSuccessPage />
                  </SimpleLayout>
                }
              />
              <Route path={ROUTES.INTEGRATIONS_ROUTE} element={<SimpleLayout />}>
                <Route
                  path={ROUTES.INTEGRATION_FULL_ROUTES.FLOW_INSTALL}
                  element={<IntegrationFlowInstallPage />}
                />
                <Route
                  path={ROUTES.INTEGRATION_FULL_ROUTES.FLOW_CREATE}
                  element={
                    <IntegrationPageDataProvider>
                      <IntegrationFlowCreatePage />
                    </IntegrationPageDataProvider>
                  }
                />
                <Route
                  path={ROUTES.INTEGRATION_FULL_ROUTES.FLOW_COMPLETE}
                  element={<IntegrationFlowCompletePage />}
                />
              </Route>
            </Route>

            <Route element={<SimpleLayout />}>
              <Route path={ROUTES.GENERIC_INTEGRATION} element={<GenericIntegration />} />
              <Route
                path={ROUTES.KYC_VERIFICATION_ROUTE}
                element={
                  <ErrorBoundary>
                    <KycVerificationPage />
                  </ErrorBoundary>
                }
              />
            </Route>

            <Route element={<SimpleLayout />}>
              <Route path={ROUTES.UNAUTHORIZED_ROUTE} element={<UnauthorizedPage />} />
            </Route>

            {/* Route with full layout */}
            <Route path='/' element={<Layout ref={contentWrapperRef} />}>
              <Route
                element={
                  <RouteAccess resource={[RESOURCE.USERS, RESOURCE.NUMBERS, RESOURCE.TEAMS]} />
                }
              >
                <Route path={ROUTES.ONBOARDING_ROUTE} element={<OnboardingPage />} />
              </Route>

              <Route path={ROUTES.HOME_ROUTE} element={<Home />} />
              <Route path={ROUTES.ERROR_TEST} element={<ErrorTestPage />} />

              <Route element={<RouteAccess resource={RESOURCE.INTEGRATIONS} />}>
                <Route
                  path={ROUTES.INTEGRATION_APPLICATIONS}
                  element={<ApplicationContainerPage />}
                />
                <Route path={ROUTES.INTEGRATIONS_ROUTE}>
                  <Route path={ROUTES.INTEGRATIONS_ROUTE} element={<IntegrationFlowRedirect />} />
                </Route>
              </Route>
              <Route element={<RouteAccess resource={RESOURCE.NUMBERS} />}>
                <Route path={ROUTES.NUMBERS_ROUTE} element={<NumbersPage />} />
                <Route
                  path={ROUTES.NUMBERS_ROUTE_TAB_ACTIVE}
                  element={<Navigate to={ROUTES.NUMBERS_ROUTE} />}
                />
                <Route
                  path={ROUTES.NUMBERS_ROUTE_TAB_UNVERIFIED}
                  element={<Navigate to={ROUTES.NUMBERS_ROUTE} />}
                />
                <Route path={ROUTES.NUMBER_DETAIL_ROUTE} element={<NumberDetailPage />} />
                <Route path={ROUTES.NUMBER_DETAIL_ROUTE_TAB} element={<NumberDetailPage />} />
              </Route>
              <Route element={<RouteAccess resource={RESOURCE.STATS} />}>
                <Route
                  path={ROUTES.STATS_ROUTE}
                  element={<Navigate to={ROUTES.STATS_CHILD_ROUTE.OVERVIEW} replace />}
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.ANALYTICS}
                  element={
                    <LegacyDashboard componentName={t('embedded_dashboard.components.stats')} />
                  }
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.ANALYTICS_PLUS}
                  element={<StatsAnalyticsPlusPage />}
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.USERS_ACTIVITY}
                  element={<StatsUsersActivityPage />}
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.USERS_ACTIVITY_PRO}
                  element={<StatsUsersActivityProPage />}
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.INBOUND_ACTIVITY}
                  element={<StatsInboundActivityPage />}
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.INBOUND_ACTIVITY_PRO}
                  element={<StatsInboundActivityProPage />}
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.OUTBOUND_ACTIVITY}
                  element={<StatsOutboundActivityPage />}
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.OUTBOUND_ACTIVITY_PRO}
                  element={<StatsOutboundActivityProPage />}
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.UNANSWERED_CALLS}
                  element={<StatsUnansweredCallsPage />}
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.UNANSWERED_CALLS_PRO}
                  element={<StatsUnansweredCallsProPage />}
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.MONITORING}
                  element={<StatsMonitoringPage />}
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.AVAILABILITIES}
                  element={<StatsAvailabilitiesPage />}
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.CALL_QUALITY}
                  element={<StatsCallQualityPage />}
                />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.CALL_QUALITY_PRO}
                  element={<StatsCallQualityProPage />}
                />
                <Route path={ROUTES.STATS_CHILD_ROUTE.OVERVIEW} element={<StatsOverviewPage />} />
                <Route
                  path={ROUTES.STATS_CHILD_ROUTE.CALL_HISTORY}
                  element={<CallsHistoryPage />}
                />
              </Route>
              <Route element={<RouteAccess resource={RESOURCE.ACTIVITY_FEED} />}>
                <Route path={ROUTES.ACTIVITY_FEED_ROUTE} element={<ActivityFeed />} />
              </Route>
              <Route element={<RouteAccess resource={RESOURCE.CALL_SETTINGS} />}>
                <Route path={ROUTES.CALLS_ROUTE} element={<CallsPage />} />
                <Route path={ROUTES.CALLS_CHILD_ROUTE.TAGS} element={<CallsTagsPage />} />
                <Route
                  path={ROUTES.CALLS_CHILD_ROUTE.BLOCKED_NUMBERS}
                  element={<CallsBlockedNumbersPage />}
                />
              </Route>
              {adminRoutesDefault.map((route) =>
                renderModuleFederationRoute(route, React.Fragment)
              )}
              <Route path='*' element={<NotFoundErrorScreen />} />
            </Route>
            {adminRoutesEmpty.map((route) => renderModuleFederationRoute(route, SimpleLayout))}
          </Routes>
        </>
      </OnboardingTrialWrapper>
    </AdminRoutesWrapper>
  );
});

function AgentRoutes() {
  const { agentRoutesDefault, agentRoutesEmpty, loading } = useModuleFederationContext();

  if (loading) {
    return <Loading data-test='agent-routes-loading' />;
  }

  return (
    <Routes>
      <Route path={ROUTES.DOWNLOAD_APP_ROUTE} element={<DownloadAppScreen />} />
      <Route
        path={ROUTES.ZENDESK_SSO}
        element={
          <SimpleLayout>
            <ZendeskSSOPage />
          </SimpleLayout>
        }
      />
      <Route
        path={ROUTES.OAUTH_LOGIN}
        element={
          <SimpleLayout>
            <OauthLoginPage />
          </SimpleLayout>
        }
      />
      {APP_CONFIG.environment !== 'production' && (
        <Route
          path={ROUTES.SANDBOX_SSO}
          element={
            <SimpleLayout>
              <SandboxSSOPage />
            </SimpleLayout>
          }
        />
      )}
      {agentRoutesDefault.map((route) => renderModuleFederationRoute(route, AgentDefaultLayout))}
      {agentRoutesEmpty.map((route) => renderModuleFederationRoute(route, SimpleLayout))}
      <Route path='*' element={<Navigate to={ROUTES.DOWNLOAD_APP_ROUTE} />} />
    </Routes>
  );
}

function RoutesList() {
  useDataDogRum();

  return (
    <PermissionsProvider>
      {({ hasDashboardAccess }) =>
        hasDashboardAccess ? (
          <GlobalDataProvider>
            <LegacyDashboardProvider>
              <DashboardExtensionDataProvider>
                <ErrorBoundary>
                  <AdminRoutes />
                </ErrorBoundary>
              </DashboardExtensionDataProvider>
            </LegacyDashboardProvider>
          </GlobalDataProvider>
        ) : (
          <AgentDataProvider>
            <DashboardExtensionDataProvider>
              <ErrorBoundary>
                <AgentRoutes />
              </ErrorBoundary>
            </DashboardExtensionDataProvider>
          </AgentDataProvider>
        )
      }
    </PermissionsProvider>
  );
}

export function PrivateRoutes() {
  return (
    <AppLayoutProvider>
      <ErrorBoundary>
        <RoutesList />
      </ErrorBoundary>
    </AppLayoutProvider>
  );
}
