import styled from '@xstyled/styled-components';
import { Link } from 'react-router-dom';

export const FloatingBannerContainer = styled.div`
  position: fixed;
  z-index: 3;
  right: 65px;
  bottom: 24px;
  width: 335px;
  height: 129px;
  animation: slide-in-from-bottom 0.3s ease-in-out;

  @keyframes slide-in-from-bottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 8px 12px;
  border-radius: 8px;
  background: linear-gradient(119deg, #195a75 2.16%, #052e3f 101.53%);
  box-shadow: 0 4px 30px 0 rgb(16 24 32 / 0.3);
  gap: 10px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: none;
  color: white;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const BannerContent = styled.div`
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 12px;
  gap: 6px;
`;

export const BannerHeadingContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
`;

export const BannerHeading = styled.div`
  color: #ffc61a;
  font-family: Fellix;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.1px;
  line-height: 16px;
`;

export const BannerDescription = styled.p`
  margin: 0;
  color: #fff;
  font-family: Fellix;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 22.4px;
`;

export const BannerLink = styled(Link)`
  color: #00b388;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
`;

export const CircularBackground = styled.div`
  position: fixed;
  z-index: 3;
  right: 65px;
  bottom: 24px;
  display: flex;
  width: 68px;
  height: 68px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(119deg, #195a75 2.16%, #052e3f 101.53%);
  box-shadow: 0 3.78px 28.33px 0 rgb(16 24 32 / 0.3);
  cursor: pointer;

  &::before {
    position: absolute;
    width: 41.56px;
    height: 41.56px;
    border: 0.94px solid;
    border-radius: 50%;
    background: linear-gradient(135deg, rgb(255 255 255 / 0.2) 0%, rgb(255 255 255 / 0.1) 100%);
    border-image-source: linear-gradient(
      135deg,
      rgb(255 255 255 / 0.3) 0%,
      rgb(255 255 255 / 0) 100%
    );
    content: '';
  }
`;
