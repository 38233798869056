import { SidenavItem, getColor } from '@aircall/tractor-v2';
import styled, { css } from '@xstyled/styled-components';

type SidenavWrapperProps = {
  $expanded: boolean;
};

export const Container = styled.nav<SidenavWrapperProps>`
  display: grid;
  block-size: 100%;
  grid-auto-rows: max-content;
  grid-template-columns: 100%;
  grid-template-rows: max-content auto;
  inline-size: 272px;
  justify-items: center;
  min-inline-size: 272px;

  /*
   * we delay min-inline-size since we don't want to transition it
   */
  transition: inline-size ease 300ms, min-inline-size 300ms 0s;

  ${({ $expanded }) =>
    !$expanded &&
    css`
      inline-size: 86px;
      min-inline-size: 86px;
    `}
`;

export const StyledHomeItem = styled(SidenavItem)`
  background: ${getColor('surface-contrast-secondary')};

  & > div:last-child {
    color: ${getColor('text-interactive-secondary')};
  }

  & > div:first-child > div {
    background-color: ${getColor('surface-interactive-secondary')};
  }

  & > div:first-child > div > svg > path {
    fill: ${getColor('surface-contrast-secondary')};
  }

  &:hover {
    background: ${getColor('surface-contrast-secondary')};
  }

  &:hover > div:last-child {
    color: ${getColor('text-interactive-secondary')};
  }
`;
