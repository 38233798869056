import { useCallback, useEffect, useState } from 'react';

import { NUMBER_DETAIL_TABS } from '../NumberDetail.decl';
import { NumberDetailHeader } from '../NumberDetailHeader/NumberDetailHeader';
import { ProofOfIdBanner } from '../ProofOfIdBanner/ProofOfIdBanner';

import { NumberDetailTabContainerProps } from './NumberDetailTab.container.decl';

import { IDType, getColor } from '@aircall/tractor-v2';
import { Placeholder } from '@dashboard/extension';
import {
  NUMBER_DETAIL_ROUTE_TAB,
  Tab,
  routeReplacer,
  useGraphLazyQuery,
  useNavigateWithParamsReplace,
} from '@dashboard/library';
import {
  getWhatsappRegistrationStatus as getWhatsappRegistrationStatusQuery,
  getWhatsappRegistrationStatus_getWhatsappRegistrationStatus,
} from '@generated/getWhatsappRegistrationStatus';
import { WhatsappRegistrationState } from '@generated/globalTypes';
import { GET_WHATSAPP_REGISTRATION_STATUS } from '@graphql/queries/GetWhatsappRegistrationStatus';
import { NotFoundError } from '@helpers/errors.helpers';
import { isRegistrationRequiredForNumber } from '@helpers/numbers.helpers';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { usePolling } from '@hooks/useGraphPolling/usePolling';
import { NumberDetailBusinessHours } from '@pages/number-detail-business-hours/NumberDetailBusinessHours.page';
import { NumberDetailCallDistribution } from '@pages/number-detail-call-distribution/NumberDetailCallDistribution.page';
import { NumberDetailIntegrationsPage } from '@pages/number-detail-integrations/NumberDetailIntegrations.page';
import { NumberDetailRegistrationStatusPage } from '@pages/number-detail-registration-status/NumberDetailRegistrationStatus.page';
import { NumberDetailsSettingsPage } from '@pages/number-detail-settings/NumberDetailSettings.page';
import { NumberDetailTeamsUsers } from '@pages/number-detail-teams-and-users/NumberDetailTeamsUsers.page';
import styled from '@xstyled/styled-components';
import { t } from 'i18next';
import { Navigate, useParams } from 'react-router-dom';

const BaseTabItem = styled(Tab.BaseTabItem)`
  background-color: ${getColor('surface-default')};
`;

const TabItem = styled(Tab.Item)`
  background-color: ${getColor('surface-default')};
`;

export function NumberDetailTabContainer({
  line,
  numberDetail,
}: Readonly<NumberDetailTabContainerProps>) {
  const { numberId, tab } = useParams();
  const navigate = useNavigateWithParamsReplace();
  const { smartflowsEnabled, enableWhatsappIntegration } = useFeaturesFlags();
  const shouldShowRegistrationStatus = isRegistrationRequiredForNumber(line);
  const availableTabs: string[] = Object.values(NUMBER_DETAIL_TABS);
  const hasSmartflowsEnabled = smartflowsEnabled && numberDetail.flowEditorEnabled;
  const [whatsappStatusInfo, setWhatsappStatusInfo] = useState<
    getWhatsappRegistrationStatus_getWhatsappRegistrationStatus | undefined
  >(undefined);
  const [isPolling, setIsPolling] = useState(false);

  const [getWhatsappRegistrationStatus] = useGraphLazyQuery<getWhatsappRegistrationStatusQuery>(
    GET_WHATSAPP_REGISTRATION_STATUS,
    {
      fetchPolicy: 'network-only',
    }
  );

  /* istanbul ignore next */
  const refreshWhatsappStatus = useCallback(async () => {
    const { data } = await getWhatsappRegistrationStatus({
      variables: {
        numberUUID: numberDetail.numberUuid,
      },
    });

    if (
      data?.getWhatsappRegistrationStatus.status !== WhatsappRegistrationState.ONLINE &&
      data?.getWhatsappRegistrationStatus.updatedAt &&
      (Date.now() - new Date(data.getWhatsappRegistrationStatus.updatedAt).getTime()) /
        (1000 * 60) <
        15
    ) {
      setWhatsappStatusInfo({
        ...data?.getWhatsappRegistrationStatus,
        status: WhatsappRegistrationState.CREATING,
      });
      setIsPolling(true);
      return;
    }

    setWhatsappStatusInfo(data?.getWhatsappRegistrationStatus);
    setIsPolling(false);
  }, [getWhatsappRegistrationStatus, numberDetail.numberUuid]);

  /* istanbul ignore next */
  usePolling({
    callback: refreshWhatsappStatus,
    interval: isPolling ? 30000 : undefined,
  });

  useEffect(() => {
    refreshWhatsappStatus();
  }, [refreshWhatsappStatus]);

  const tabs = [
    {
      id: NUMBER_DETAIL_TABS.CALL_DISTRIBUTION,
      trans: 'number_details.tabs.call_distribution',
      shouldDisplay: true,
    },
    {
      id: NUMBER_DETAIL_TABS.SETTINGS,
      trans: 'number_details.tabs.settings',
      shouldDisplay: true,
    },
    {
      id: NUMBER_DETAIL_TABS.INTEGRATIONS,
      trans:
        /* istanbul ignore next */
        enableWhatsappIntegration
          ? 'number_details.tabs.capability'
          : 'number_details.tabs.integrations',
      shouldDisplay: true,
    },
    {
      id: NUMBER_DETAIL_TABS.BUSINESS_HOURS,
      trans: 'number_details.tabs.business_hours',
      shouldDisplay: !hasSmartflowsEnabled,
    },
    {
      id: NUMBER_DETAIL_TABS.TEAMS_AND_USERS,
      trans: 'number_details.tabs.teams&users',
      shouldDisplay: true,
    },
    {
      id: NUMBER_DETAIL_TABS.REGISTRATION_STATUS,
      trans: 'number_details.tabs.registration_status',
      shouldDisplay: shouldShowRegistrationStatus,
    },
  ];
  const displayedTabs = tabs.filter(({ shouldDisplay }) => shouldDisplay);
  const displayedTabIds = displayedTabs.map(({ id }) => id);

  const onTabChange = useCallback(
    (tabId: IDType) => {
      navigate(NUMBER_DETAIL_ROUTE_TAB, { numberId, tab: tabId! });
    },
    [navigate, numberId]
  );

  if (!tab) {
    return (
      <Navigate
        to={routeReplacer(NUMBER_DETAIL_ROUTE_TAB, {
          numberId,
          tab: NUMBER_DETAIL_TABS.CALL_DISTRIBUTION,
        })}
        replace
      />
    );
  }

  if (!availableTabs.includes(tab) || !displayedTabIds.includes(tab)) {
    throw new NotFoundError('Tab not found', { tab });
  }

  return (
    <NumberDetailHeader
      line={line}
      numberDetail={numberDetail}
      activeTabId={tab}
      onTabChange={onTabChange}
      shouldShowRegistrationStatus={shouldShowRegistrationStatus}
      whatsappStatus={whatsappStatusInfo?.status}
    >
      <Tab.Menu>
        {displayedTabs.map(({ id, trans }) => (
          <Tab.MenuItem key={id} id={id}>
            {t(trans)}
          </Tab.MenuItem>
        ))}
      </Tab.Menu>

      <Placeholder
        name='purchase_flow_success_modal'
        parameters={{
          numberId: numberDetail.id,
          numberFormatted: numberDetail.numberFormatted,
          numberName: numberDetail.name,
          numberUuid: numberDetail.numberUuid!,
          verificationState: numberDetail.verificationState ?? '',
        }}
        data-test='new_number_modal'
      />

      <ProofOfIdBanner numberDetail={numberDetail} />

      <Tab.Content>
        {hasSmartflowsEnabled ? (
          <BaseTabItem id={NUMBER_DETAIL_TABS.CALL_DISTRIBUTION}>
            <Placeholder name='flow_editor_view' parameters={{ lineId: numberId }} />
          </BaseTabItem>
        ) : (
          <TabItem id={NUMBER_DETAIL_TABS.CALL_DISTRIBUTION}>
            <NumberDetailCallDistribution numberDetail={numberDetail} />
          </TabItem>
        )}
        <TabItem id={NUMBER_DETAIL_TABS.SETTINGS}>
          <NumberDetailsSettingsPage {...numberDetail} />
        </TabItem>
        <TabItem id={NUMBER_DETAIL_TABS.INTEGRATIONS}>
          <NumberDetailIntegrationsPage
            whatsappStatusInfo={whatsappStatusInfo}
            setWhatsappStatusInfo={setWhatsappStatusInfo}
            refreshWhatsappStatus={refreshWhatsappStatus}
            numberUUID={line?.numberUUID}
            provider={line?.provider}
            capabilities={line?.capabilities}
          />
        </TabItem>
        <TabItem id={NUMBER_DETAIL_TABS.BUSINESS_HOURS}>
          <NumberDetailBusinessHours numberDetail={numberDetail} />
        </TabItem>
        <TabItem id={NUMBER_DETAIL_TABS.TEAMS_AND_USERS}>
          <NumberDetailTeamsUsers numberDetail={numberDetail} />
        </TabItem>
        <TabItem id={NUMBER_DETAIL_TABS.REGISTRATION_STATUS}>
          <NumberDetailRegistrationStatusPage numberDetail={numberDetail} />
        </TabItem>
      </Tab.Content>
    </NumberDetailHeader>
  );
}
