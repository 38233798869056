import { useCallback, useState } from 'react';

import {
  Banner,
  BannerHeading,
  BannerIcon,
  Radio,
  RadioGroup,
  RowActionType,
  Spacer,
  Table,
  TableColumn,
  Typography,
} from '@aircall/tractor-v2';
import { AddButton } from '@components/Button/AddButton';
import { IntegrationFlowPage } from '@components/IntegrationFlowPage/IntegrationFlowPage';
import { MultiSelectLinesModal } from '@components/MultiSelectLinesModal';
import { PhoneNumber } from '@components/PhoneNumber';
import {
  APPLICATIONS_NAMES,
  SELECT_ALL_NUMBERS_PARAM_VALUE,
} from '@constants/integrations.constants';
import { GridItem, Loading, Paper, capitalizeFirstLetter } from '@dashboard/library';
import { ApplicationsListQuery_getApplications } from '@generated/ApplicationsListQuery';
import { GetAuthorizedApplicationQuery_getAuthorizedApplication } from '@generated/GetAuthorizedApplicationQuery';
import { SearchLinesQuery_searchAuthorizedLines_items as Line } from '@generated/SearchLinesQuery';
import { NotFoundError } from '@helpers/errors.helpers';
import { useIntegrations } from '@hooks/useIntegrations';
import { useToggle } from '@hooks/useToggle/useToggle';
import { useTranslation } from 'react-i18next';

interface IntegrationFlowSelectNumbersProps {
  authApplication?: GetAuthorizedApplicationQuery_getAuthorizedApplication;
  applicationName?: APPLICATIONS_NAMES;
  onlySpecificNumbers?: boolean;
  onSubmit?: (linesIds: Line[] | typeof SELECT_ALL_NUMBERS_PARAM_VALUE) => void;
  onSkip?: () => void;
}

enum SELECT_OPTIONS {
  SELECT_ALL = 'SELECT_ALL',
  SELECT_NUMBERS = 'SELECT_NUMBERS',
}

export function IntegrationFlowSelectNumbers({
  authApplication,
  applicationName,
  onlySpecificNumbers = false,
  onSubmit,
  onSkip,
}: IntegrationFlowSelectNumbersProps) {
  const { t } = useTranslation();
  const { isLoading, getApplicationByName } = useIntegrations();
  const [isModalOpen, _, openModal, closeModal] = useToggle(false);
  const [selectedLines, setSelectedLines] = useState<Line[]>([]);
  const [selectOption, setSelectOption] = useState<SELECT_OPTIONS>(
    onlySpecificNumbers ? SELECT_OPTIONS.SELECT_NUMBERS : SELECT_OPTIONS.SELECT_ALL
  );

  const handleModalSubmit = useCallback(
    (newLines: Line[]) => {
      closeModal();
      setSelectedLines((lines) => [
        ...lines,
        // Remove duplicates from lines before adding
        ...newLines.filter((line: Line) => lines.findIndex((l) => l.ID === line.ID) === -1),
      ]);
    },
    [closeModal]
  );

  const handlePageSubmit = useCallback(() => {
    onSubmit &&
      onSubmit(
        selectOption === SELECT_OPTIONS.SELECT_ALL ? SELECT_ALL_NUMBERS_PARAM_VALUE : selectedLines
      );
  }, [onSubmit, selectedLines, selectOption]);

  const handleDeleteSelectedLine = (line: Line) =>
    setSelectedLines((lines) => lines.filter((l) => l.ID !== line.ID));

  if (isLoading) {
    return <Loading data-test='loading' />;
  }

  const application = authApplication || (applicationName && getApplicationByName(applicationName));

  if (!application) {
    throw new NotFoundError(`Application ${applicationName} not found`);
  }

  const { logo } = application;
  const name = (application as ApplicationsListQuery_getApplications).shortName || application.name;

  const tableColumns: TableColumn<Line>[] = [
    {
      id: 'Name',
      label: t('integration_flow.select_numbers.table.column_name'),
      renderer: (line) => (
        <Typography variant='bodyMediumS' ellipsis>
          {line?.name}
        </Typography>
      ),
    },
    {
      id: 'number',
      label: t('integration_flow.select_numbers.table.column_number'),
      renderer: (line) => <PhoneNumber number={line?.phoneNumber!} />,
    },
  ];

  return (
    <>
      <MultiSelectLinesModal
        title='Add numbers'
        isOpen={isModalOpen}
        linesBlacklist={selectedLines}
        onSubmit={handleModalSubmit}
        onClose={closeModal}
      />
      <IntegrationFlowPage
        title={capitalizeFirstLetter(name)}
        paperTitle={t('integration_flow.select_numbers.sub_title')}
        avatarSrc={logo}
        hasFooter
        submitButtonText={t('integration_flow.select_numbers.add_numbers')}
        onSubmit={handlePageSubmit}
        cancelButtonText={t('integration_flow.select_numbers.skip')}
        onCancel={onSkip}
        shouldDisableSubmit={
          selectOption === SELECT_OPTIONS.SELECT_NUMBERS && !selectedLines.length
        }
      >
        <Paper fluid>
          <GridItem xs={12}>
            <Spacer direction='vertical' space='m' fluid>
              <Spacer direction='vertical' space='s'>
                <Typography variant='bodyMediumS'>
                  {t('integration_flow.select_numbers.text')}
                </Typography>

                <Banner inline variant='info'>
                  <BannerIcon data-test='hello-icon' />
                  <BannerHeading>
                    {t('integration_flow.select_numbers.banner_message')}
                  </BannerHeading>
                </Banner>
              </Spacer>

              {!onlySpecificNumbers && (
                <RadioGroup
                  value={selectOption}
                  direction='vertical'
                  onChange={setSelectOption}
                  gap='s'
                >
                  <Radio value={SELECT_OPTIONS.SELECT_ALL} data-test='radio-select-all'>
                    {t('integration_flow.select_numbers.select_all_numbers')}
                  </Radio>
                  <Radio value={SELECT_OPTIONS.SELECT_NUMBERS} data-test='radio-select-specific'>
                    {t('integration_flow.select_numbers.select_specific_numbers')}
                  </Radio>
                </RadioGroup>
              )}

              {selectOption === SELECT_OPTIONS.SELECT_NUMBERS && (
                <Spacer direction='vertical' fluid>
                  {!!selectedLines.length && (
                    <Table
                      data-test='selected-lines-table'
                      w='100%'
                      data={selectedLines}
                      columns={tableColumns}
                      rowActionsType={RowActionType.Dropdown}
                      rowActions={[
                        {
                          label: t('integration_flow.select_numbers.table.remove_number'),
                          onExecute: handleDeleteSelectedLine,
                        },
                      ]}
                    />
                  )}

                  <AddButton
                    data-test='add-numbers'
                    hasData={!!selectedLines.length}
                    onClick={openModal}
                    translationKey='integration_flow.select_numbers.add_numbers'
                  />
                </Spacer>
              )}
            </Spacer>
          </GridItem>
        </Paper>
      </IntegrationFlowPage>
    </>
  );
}
