import { gql } from '@apollo/client';

export const GET_WHATSAPP_REGISTRATION_STATUS = gql`
  query getWhatsappRegistrationStatus($numberUUID: String!) {
    getWhatsappRegistrationStatus(numberUUID: $numberUUID) {
      status
      phoneNumber {
        healthStatus {
          canSendMessage
          entities {
            entityType
            id
            canSendMessage
            errors {
              errorCode
              errorDescription
              possibleSolution
            }
            additionalInfo
          }
        }
        codeVerificationStatus
        messagingLimitTier
        throughput {
          level
        }
        id
        nameStatus
        newNameStatus
        status
        offlineReasons {
          errorKey
          code
          description
          solution
        }
      }
      wabaId
      updatedAt
    }
  }
`;
