import { useEffect } from 'react';

import { useCurrentUser } from './useCurrentUser/useCurrentUser';

import {
  APP_CONFIG,
  DATADOG_APPLICATION_ID,
  DATADOG_APP_HOSTNAME,
  DATADOG_TOKEN,
} from '@constants/environment.constants';
import { DefaultPrivacyLevel } from '@datadog/browser-core';
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import { isDev } from '@helpers/env.helpers';

const datadogConfig: RumInitConfiguration = {
  allowedTracingOrigins: [DATADOG_APP_HOSTNAME],
  applicationId: DATADOG_APPLICATION_ID,
  clientToken: DATADOG_TOKEN,
  site: 'datadoghq.com',
  service: 'dashboard-v4',
  env: APP_CONFIG.environment,
  version: APP_CONFIG.release,
  sampleRate: 100,
  tracingSampleRate: 20,
  trackInteractions: true,
  trackFrustrations: true,
  defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
};

export const useDataDogRum = (): void => {
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (!currentUser || isDev()) {
      return;
    }

    datadogRum.init(datadogConfig);
    datadogRum.startSessionReplayRecording();
    datadogRum.setUser({
      id: currentUser.ID,
      name: `company_id_${currentUser.companyID}`,
    });

    datadogRum.setGlobalContextProperty('user_session', {
      environment: APP_CONFIG.environment,
      release: APP_CONFIG.release,
      service: 'dashboard-v4',
    });

    // eslint-disable-next-line consistent-return
    return () => {
      datadogRum.removeGlobalContextProperty('user_session');
      datadogRum.clearUser();
      datadogRum.stopSessionReplayRecording();
    };
  }, [currentUser]);
};
